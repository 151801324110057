<template>
    <div style="display: flex; flex-wrap: wrap; gap: 15px; max-width: 100vw">
        <v-select
            v-model="_companySelected"
            return-object
            style="max-width: 500px;"
            :items="companies"
            attach
            :label="textLabel || $t('companies.selectCompany')"
            item-text="attributes.name"
            item-value="id"
            clearable
        />

        <div style="max-width: 150px;">
            <v-img
                v-if="_companySelected"
                :src="getImagePath()"
                height="60px"
                contain
                class="my-1 img-effect"
            />
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    name: "CompanySelector",
    props: {
        companyIdSelected: {
            required: false
        },
        textLabel: {
            type: String,
            required: false
        }
    },
    data: function () {
        return {
            companies: [],
            companySelected: null
        }
    },
    computed: {
        _companySelected: {
            get() {
                if (this.companyIdSelected) {
                    if (!this.companySelected || this.companySelected.id != this.companyIdSelected) {
                        return this.companies.find(company => this.companyIdSelected == company.id);
                    }
                }
                return this.companySelected;
            },
            set(value) {
                this.companySelected = value;
                this.$emit('select:company', this.companySelected);
            }
        }
    },
    async created() {
        await this.fetchCompanies();
    },
    methods: {
        async fetchCompanies() {
            try {
                const result = await axios
                    .get("/companies");

                this.companies = result.data.data;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        getImagePath() {
            const image = this._companySelected?.attributes?.logo ?? '';

            return image ? process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=companies/images/' + image : '';
        }
    }
}
</script>

<style scoped>
.img-effect{
  -webkit-filter: drop-shadow(0 3px 3px #222 );
  filter: drop-shadow(0 3px 3px #222);
}
</style>
